var data = {
  'diagnosis_list': [{
    'value': '1',
    'label': 'C50 - Meme Malign Neoplazm'
  }, {
    'value': '2',
    'label': 'C18 - Kolon Malign Neoplazm'
  }, ],
  'sex': '0', // 0 erkek, 1 kadın
  'age': 55,
  'color': 1,
  'last_laboratory': {
    'creatinine': {
      'date': '2022-01-01T09:00:00.000000',
      'val': 1.2,
      'gfr': 70
    }
  },
  'last_bsa_data': {
    'adjusted_weight_kg': 87,
    'adjusted_weight_lbs': 192,
    'bmi': 29.54,
    'bsa': 2,
    'bsa_date': '2022-01-01',
    'height_cm': 184,
    'height_inch': 72.44,
    'id': 1,
    'ideal_weight_kg': 79,
    'ideal_weight_lbs': 174,
    'max_vya_is_2': 1,
    'weight_kg': 100,
    'weight_lbs': 220,
  }
};

export { data as example_data };
