<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'gfrpage'}"></work-subject>
        <hr>
        <h3>Gfr Komponenti</h3>
        <gfr :p_patientData="d_patientData"></gfr>
        <hr>
        <template v-for="(code_example, code_example_index)  in d_componentExample.gfr_widget">
          <h3> {{ code_example.name }} </h3>
          <div class="code-text">{{ code_example.code }}</div>
        </template>
        <hr>
        <sql-database :p_tableShowDetails="{'type': 'selected', 'table_name': 'PatientLaboratory'}"></sql-database>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as Gfr
} from '@/components/widgets/Gfr';
import { mapGetters } from 'vuex';
export default {
  name: 'GfrPage',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    SqlDatabase,
    Gfr
  },
  props: {},
  data () {
    return {
      d_componentExample: {
        'gfr_widget': [
          { 'code': '<gfr :p_patientData="d_patientData"></gfr>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
      d_patientData: require('@/example_data/patient_data').example_data,
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

