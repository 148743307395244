<template>
  <div>
    <b-row v-if="p_patientData">
      <b-col cols="12">
        <b-card class="card-accent-success" no-body style="margin-bottom: 3px;">
          <b-card-header header-bg-variant="white" class="p-1">
            <span v-if="d_gfrData.creatinine && p_patientData.last_bsa_data && p_patientData.last_bsa_data.bsa_date"> Son kreatinin tarihi {{ DateTimeFormatWithDayDif(d_gfrData.date) }} </span>
            <span v-else>
              ( Henüz GFR hesabı için gerekli olan {{ !d_gfrData.creatinine ? 'kreatinin' : '' }} {{ !p_patientData.last_bsa_data.bsa_date ? 'kilo boy' : '' }} kaydı yok. Lütfen giriş yapınız. )
            </span>
            <b-button size="sm" class="pull-right" @click="f_createNewCreatinin()" variant="success"><i class="icon-plus">Yeni Kreatinin</i></b-button>
          </b-card-header>
          <b-row v-if="d_gfrData.gfr">
            <b-col cols="12">
              <b-row>
                <b-col sm="4" md="4">
                  Kreatinin :
                  <template v-if="d_gfrData && d_gfrData.creatinine !== undefined">
                    <span v-if="d_showMgdl"> {{ d_gfrData.creatinine }} </span>
                    <span v-else> {{ (d_gfrData.creatinine * 88.4).toFixed(1) }} </span>
                    <span v-if='d_showMgdl' @click="d_showMgdl = false" style="cursor: pointer;">mg/dl<i class="fa fa-hand-o-up"></i></span>
                    <span v-else @click="d_showMgdl = true" style="cursor: pointer;">umol/L<i class="fa fa-hand-o-up"></i>;</span>
                  </template>
                </b-col>
                <b-col sm="8" md="8">
                  GFR (
                  <template v-if="d_gfrData && d_gfrData.selected_gfr_formule && d_gfrData.selected_gfr_formule === 'cockcroft_gault'">
                    <span style="cursor: pointer;" v-if="p_patientData.color !== 0" @click="d_gfrData.selected_gfr_formule = 'mdrd'; gfr_calculate()">COCKCROFT-GAULT <i class="fa fa-hand-o-up"></i></span>
                    <span v-else-if="p_patientData.color === 0">COCKCROFT-GAULT <i class="fa fa-hand-o-up"></i></span>
                  </template>
                  <span style="cursor: pointer;" v-if="d_gfrData && d_gfrData.selected_gfr_formule === 'mdrd'" @click="d_gfrData.selected_gfr_formule = 'cockcroft_gault'; gfr_calculate()">MDRD <i class="fa fa-hand-o-up"></i></span> ) : {{ d_gfrData.gfr }} ml/dakika
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4" @click="d_gfrData.selected_weight_for_gfr = 'actual_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                  <input type="radio" value="actual_weight_gfr" v-model="d_gfrData.selected_weight_for_gfr" id="actual_weight"> Gerçek Kilo {{p_patientData.last_bsa_data.weight_kg}} kg
                </b-col>
                <b-col cols="4" @click="d_gfrData.selected_weight_for_gfr = 'adjusted_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                  <input type="radio" value="adjusted_weight_gfr" v-model="d_gfrData.selected_weight_for_gfr" id="adjusted_weight"> Düzeltilmiş Kilo {{p_patientData.last_bsa_data.adjusted_weight_kg}} kg
                </b-col>
                <b-col cols="4" @click="d_gfrData.selected_weight_for_gfr = 'ideal_weight_gfr'; gfr_calculate()" style="cursor: pointer;">
                  <input type="radio" value="ideal_weight_gfr" v-model="d_gfrData.selected_weight_for_gfr" id="ideal_weight"> İdeal Kilo {{p_patientData.last_bsa_data.ideal_weight_kg}} kg
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <modal v-if="d_showCreatinineModal" @close="d_showCreatinineModal = false" :p_width="'500'">
      <h3 slot="header">Kreatinin Ekranı</h3>
      <div slot="body">
        <b-row>
          <b-col cols="6">
            <strong> Tarih </strong>
          </b-col>
          <b-col cols="4">
            <b-form-input type="date" :max="d_maxDate" v-model="d_creatinineData.date"></b-form-input>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <strong> Kreatinin </strong>
          </b-col>
          <b-col cols="4">
            <b-form-input type="number" v-model="d_creatinineData.val"></b-form-input>
          </b-col>
          <b-col cols="2">
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveCreatinine()">Kaydet</button>
        <button type="button" class="btn btn-danger" @click="d_showCreatinineModal = false">Kapat</button>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  default as Modal
} from '@/components/widgets/Modal';
import { mapGetters } from 'vuex';
import { DateFunctions } from '@/public/DateFunctions';
import moment from 'moment';

export default {
  name: 'Gfr',
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreLoading: 'StoreLoading',
    })
  },
  components: {
    Modal,
  },
  props: {
    p_patientData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_showCreatinineModal: false,
      d_showMgdl: true,
      d_gfrData: {
        'gfr': '',
        'date': '',
        'selected_gfr_formule': 'cockcroft_gault',
        'selected_weight_for_gfr': 'actual_weight_gfr',
        'creatinine': ''
      },
      d_creatinineData: {
        'date': '',
        'weight': '',
        'lenght': '',
      },
      d_user: {},
      d_maxDate: moment(new Date().toISOString()).format('YYYY-MM-DD'),
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.p_patientData.last_laboratory && this.p_patientData.last_laboratory.creatinine && this.p_patientData.last_laboratory.creatinine.val) {
      this.d_gfrData.creatinine = this.p_patientData.last_laboratory.creatinine.val;
      this.d_gfrData.date = this.p_patientData.last_laboratory.creatinine.date;
    }
    this.gfr_calculate();
  },
  mounted () {},
  methods: {
    f_createNewCreatinin: function () {
      this.d_showCreatinineModal = true;
    },
    f_saveCreatinine: function () {
      this.d_showCreatinineModal = false;
      let day_dif = DateFunctions.day_dif(this.d_gfrData.date, this.d_creatinineData.date);
      if (day_dif >= 0) {
        this.p_patientData.last_laboratory.creatinine.val = this.d_creatinineData.val;
        this.d_gfrData.creatinine = this.d_creatinineData.val;
        this.d_gfrData.date = this.d_creatinineData.date;
        this.gfr_calculate();
      }
    },
    DateTimeFormatWithDayDif: function (date) {
      let day_dif = DateFunctions.date_dif_today(date);
      if (day_dif === 0) {
        return moment(date).format('DD/MM/YYYY') + ' (bugün)';
      } else if (day_dif === 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün sonra)';
      } else if (day_dif > 1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün sonra)';
      } else if (day_dif === -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün önce)';
      } else if (day_dif < -1) {
        return moment(date).format('DD/MM/YYYY') + ' (' + Math.abs(day_dif) + ' gün önce)';
      }
      return moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY');
    },
    gfr_calculate: function () {
      if (this.p_patientData.last_bsa_data.bsa && this.d_gfrData.creatinine) {
        if (!this.d_gfrData.selected_gfr_formule) {
          let bmi = parseFloat(this.p_patientData.last_bsa_data.bmi);
          if (bmi < 18.5) {
            this.d_gfrData.selected_weight_for_gfr = 'actual_weight_gfr';
          } else if (bmi > 25) {
            this.d_gfrData.selected_weight_for_gfr = 'adjusted_weight_gfr';
          } else {
            this.d_gfrData.selected_weight_for_gfr = 'ideal_weight_gfr';
          }
          this.d_gfrData.selected_gfr_formule = 'cockcroft_gault';
        }
        let gfr = 0;
        let a = parseFloat(this.p_patientData.last_bsa_data.weight_kg);
        if (this.d_gfrData.selected_gfr_formule === 'cockcroft_gault') {
          if (this.d_gfrData.selected_weight_for_gfr === 'adjusted_weight_gfr') {
            a = parseFloat(this.p_patientData.last_bsa_data.adjusted_weight_kg);
          } else if (this.d_gfrData.selected_weight_for_gfr === 'ideal_weight_gfr') {
            a = parseFloat(this.p_patientData.last_bsa_data.ideal_weight_kg);
          }
          if (this.p_patientData.sex === '0') {
            let res = (((140 - this.p_patientData.age) * a) / (72 * parseFloat(this.d_gfrData.creatinine)));
            gfr = res.toFixed(0);
          } else { // if female
            let res = (((140 - this.p_patientData.age) * a) / (72 * parseFloat(this.d_gfrData.creatinine))) * 0.85;
            gfr = res.toFixed(0);
          }
        } else { // mdrd formülü GFR = 186 × Serum Cr-1.154 × age-0 .203 × 1.212 (if patient is black) × 0.742 (if female)
          if (this.p_patientData.color === 1) {
            if (this.p_patientData.sex === '0') { // '0' erkek
              gfr = 186 * Math.pow(parseFloat(this.d_gfrData.creatinine), -1.154) * Math.pow(this.p_patientData.age, -0.203);
            } else { // 1 kadın ise
              gfr = 186 * Math.pow(parseFloat(this.d_gfrData.creatinine), -1.154) * Math.pow(this.p_patientData.age, -0.203) * 0.742;
            }
          } else if (this.p_patientData.color === 2) { // if race = black
            if (this.p_patientData.sex === '0') { // 0 erkek
              gfr = 186 * Math.pow(parseFloat(this.d_gfrData.creatinine), -1.154) * Math.pow(this.p_patientData.age, -0.203) * 1.212;
            } else { // 1 kadın ise
              gfr = 186 * Math.pow(parseFloat(this.d_gfrData.creatinine), -1.154) * Math.pow(this.p_patientData.age, -0.203) * 0.742 * 1.212;
            }
          }
        }
        this.d_gfrData.gfr = parseFloat(gfr).toFixed(0);
      } else if (!this.p_patientData.last_bsa_data.bsa) {
        if (this.d_gfrData) {
          this.d_gfrData.gfr = '';
        }
      }
      this.p_patientData.last_laboratory.creatinine.gfr = this.d_gfrData.gfr;
    }
  },
  watch: {}
};

</script>

<style type="text/css">


</style>

